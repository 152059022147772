.header {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(128, 128, 128, 0.5);;
    display: flex; /* enable flex Layout */
    align-items: center; /* vertically center logo and menu */
    justify-content: space-between; /* separate logo and menu */
    padding: 0 22px; /* add padding to separate logo and menu from edges */
    line-height: 50px;
    margin-bottom: 2px;
}
.languageButton1, .languageButton2 {
    text-transform: unset !important;
    font-size: 0.8rem !important;
    padding: 0.2em 0.5em !important; /* Adjust padding to ensure text fits */
    border-radius: 6px !important;
    color: grey;
    border: none;
    box-shadow: none;
    height: 30px; /* Adjust height for better visibility */
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 70px; /* Set a minimum width */
    flex-grow: 1; /* Allow buttons to grow equally */
}

.languageButtonsContainer {
    display: flex;
    gap: 10px; /* Space between buttons */
}
.activeLanguage{
    background: #377EF3 !important;
    color: white !important;
}
.inactiveLanguage{
    color: black !important;
}

.headerRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.logo {
    height: 64px;
    cursor: pointer;
}

.menuWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.menuContent {
    display: flex;
    align-items: center;
    gap: 20px; /* Adjust the gap as needed */
}

.username {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
}

.logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: #387FF7;
}

.logout label {
    line-height: 1;
    font-size: 10px;
    color: #387FF7;
}